import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { css, Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as am4core from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { Header } from './components/Header';
import { ChartScreen } from './screens/Chart';
import ReactTooltip from 'react-tooltip';

am4core.useTheme(am4themes_animated);

const global = css`
  html {
    background: #010d17;
  }

  body {
    font-family: 'Exo 2', sans-serif;
    padding: 4rem 5rem;
    /* max-width: 1444px; */

    @media (max-width: 420px) {
      padding: 0;
    }
  }

  body, a {
    color: white;
  }

  p, strong, h3 {
    font-weight: 300;
    font-size: 1.125rem;
    line-height: 1.5rem;
  }

  strong, h3 {
    font-weight: 700;
  }

  svg[data-tip] {
    outline: none;
    position: relative;
    top: 5px;
    left: 6px;
  }

  .tooltip {
    max-width: 450px;
    padding: 1rem !important;
    line-height: 1.2;
    font-size: 1rem !important;
    transition: none !important;
    font-weight: 300 !important;

    &.show {
      opacity: 1 !important;
    }
  }
`;

const queryClient = new QueryClient({

});

export const App: React.FC = () => {
  return <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <Global styles={global}/>

      <Header/>

      <Switch>
        <Route path="/:customer/:serialNo" component={ChartScreen}/>
        <Route path="/:customer" component={ChartScreen}/>
      </Switch>

      <ReactTooltip className="tooltip" border borderColor="#fffffe" backgroundColor="#010d17"/>
    </BrowserRouter>
  </QueryClientProvider>;
}