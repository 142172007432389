import React from 'react';
import styled from '@emotion/styled';

const StyledSection = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-areas: "title       title"
                       "description content";
  column-gap: 6rem;

  @media (max-width: 420px) {
    grid-template-columns: 1fr;
    grid-template-areas: "title"
                         "description"
                         "content";
  }

  h2 {
    grid-area: title;
    font-weight: 400;
    font-size: 2rem;
    padding: 2rem 0;

    img {
      margin-right: 1.5rem;
    }
  }

  p:first-of-type {
    grid-area: description;
  }
`;

interface Props {
  iconUrl: string;
  title: string;
  description: string;
}

export const SplitDescription: React.FC<Props> = ({ iconUrl, title, description, children }) => {
  return <StyledSection>
    <h2>
      <img src={iconUrl}/>
      { title }
    </h2>
    <p>{ description }</p>
    <div>{ children }</div>
  </StyledSection>;
}