import React, { useMemo } from 'react';
import styled from '@emotion/styled';
import { useParams } from 'react-router-dom';
import * as math from 'mathjs';

import iconChartSVG from 'url:../assets/icon_charts.svg';
import { SplitDescription } from '../components/SplitDescription';
import { MachinesList } from '../components/MachinesList';
import { ChartEntry, useAPIChartData, useAPIMachines } from '../api';
import { DataChart } from '../components/DataChart';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/react';
import ReactTooltip from 'react-tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const Reduction = styled.strong`
  color: #00b9ed;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 400;
`;

const Container = styled.main`
  margin-top: 3rem;
  display: grid;
  grid-template-columns: 314px 1fr;
`;

const loaderCSS = css`
  margin: 4rem auto;
`;

interface Params {
  customer: string;
  serialNo: string;
}

function meanFor(anc: number, data?: ChartEntry[]) {
  const filtered = data?.filter((entry) => entry.anc === anc);

  if (!filtered || filtered.length < 1) {
    return;
  }

  return math.mean(
    filtered.map((entry) => entry.SPLon),
  );
}

export const ChartScreen: React.FC = () => {
  const { customer, serialNo } = useParams<Params>();

  const chartQuery = useAPIChartData(customer, serialNo);
  const machinesQuery = useAPIMachines(customer);
  const machine = machinesQuery.data?.find((machine) => machine.serialNo === serialNo);

  const calculations = useMemo(() => {
    const meanANCOn = meanFor(1, chartQuery.data);
    const meanANCOff = meanFor(0, chartQuery.data);

    if (!meanANCOn || !meanANCOff) {
      return { spl: undefined, percent: undefined };
    }

    const spl = meanANCOff - meanANCOn;

    return {
      spl,
      percent: (1 - Math.pow(2, -spl / 10)) * 100,
    }
  }, [ chartQuery.data ]);

  return <>
    <SplitDescription
      title="Active Noise Cancelling Charts"
      description={`Wir machen Lärmreduktion und Prävention sichtbar. Hab volle Transparenz über Deinen Beitrag für mehr Lebensqualität am Arbeitsplatz. Auf einem Blick siehst Du die Lärmreduktion, die über einen bestimmten Zeitraum auf einer Maschine erzielt wurde.`}
      iconUrl={iconChartSVG}>
        <h3>
          recalm reduction
          <IoIosInformationCircleOutline size="26px" data-tip={`Für die Berechnung der Lärmreduktion wird der durchschnittliche Lärmpegel bei ANC an und ANC aus über alle Messpunkte erfasst. Aus der Differenz resultiert die Lärmreduktion in dB. Die Reduktion in % entspricht der hörbaren, psychoakustischen Lärmreduktion.`}/>
        </h3>
        <Reduction>
          { calculations.spl?.toFixed(2) ?? '--' } dB / { calculations.percent?.toFixed(2) ?? '--' }%
        </Reduction>
    </SplitDescription>
    <Container>
      <MachinesList/>
      { chartQuery.isFetched ? <DataChart name={machine ? `${machine.manufacturer} ${machine.model}` : ''} data={chartQuery.data ?? []}/> : <PulseLoader color="#00b9ed" css={loaderCSS}/> }
      <div>
        
      </div>
    </Container>
  </>;
};