import React from 'react';
import styled from '@emotion/styled';
import { NavLink, useParams } from 'react-router-dom';
import { useAPIMachines } from '../api';
import { PulseLoader } from 'react-spinners';
import { css } from '@emotion/react';
import ReactTooltip from 'react-tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/io';

const Container = styled.aside`
  h3 {
    margin-bottom: 0.5rem;
  }

  ul li {
    border-bottom: 1px solid white;

    a {
      display: inline-block;
      font-size: 1.25rem;
      font-weight: 300;
      text-decoration: none;
      padding: 0.6rem 0.75rem;

      &.active {
        font-weight: 700;
        color: #00b5ea;
      }
    }
  }
`;

const loaderCSS = css`
  margin: 4rem auto;
  width: 57px;
  display: block;
`;

interface Params {
  customer: string;
}

export const MachinesList: React.FC = () => {
  const { customer } = useParams<Params>();

  const machinesQuery = useAPIMachines(customer);

  return <Container>
    <h3>
      Machines
      <IoIosInformationCircleOutline size="26px" data-tip={`Hier siehst Du die Maschinen in Deinem Fuhrpark, die mit ANCOR ausgestattet sind.`}/>
    </h3>
    { machinesQuery.isLoading ? <PulseLoader color="#00b9ed" css={loaderCSS}/> : <ul>
      { machinesQuery.data?.map((machine) => <li key={machine.serialNo}>
        <NavLink to={`/${customer}/${machine.serialNo}`}>{ machine.manufacturer } { machine.model }</NavLink>
      </li>) }
    </ul> }
  </Container>;
};