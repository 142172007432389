import React from 'react';
import styled from '@emotion/styled';

import logoSVG from 'url:../assets/logo.svg';

const StyledHeader = styled.header`
  margin-bottom: 2rem;
`;

export const Header: React.FC = () => {
  return <StyledHeader>
    <img src={logoSVG}/>
  </StyledHeader>
}

