import React, { useEffect, useRef } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ChartEntry } from '../api';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import ReactTooltip from 'react-tooltip';

const Span = styled.span`
  color: #51b2e6;
`;

const Legend = styled.aside`
  float: right;
  display: inline-flex;

  div {
    position: relative;
    margin-left: 3rem;

    &:before {
      content: ' ';
      width: 1rem;
      height: 4px;
      background: #fffffe;
      display: block;
      position: absolute;
      top: 0.75rem;
      left: -1.5rem;
    }

    &.on:before {
      background: #51b2e6;
    }

    &.device-off:before {
      height: 1rem;
      top: 0.25rem;
      background: rgba(255, 255, 255, 0.2);
    }
  }  
`;

interface Props {
  data: ChartEntry[];
  name: string;
}

export const DataChart: React.FC<Props> = ({ data, name }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<am4charts.XYChart>();

  function formatData() {
    return data.map((entry) => ({
      ...entry,
      date: new Date(entry.date),
      color: entry.anc === 1 ? '#51b2e6' : '#fffffe',
      strokeWidth: entry.anc === 1 ? 2 : 1,
    }));
  }

  useEffect(() => {
    if (!chartRef.current || chartRef.current.isDisposed) {
      return;
    }

    chartRef.current.data = formatData();
  }, [ data ]);

  useEffect(() => {
    if (!containerRef.current || data.length < 1) {
      return;
    }

    const chart = am4core.create(containerRef.current, am4charts.XYChart);
    const chartData = formatData();
    chart.data = chartData;
  
    const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.grid.template.stroke = am4core.color('white');
    dateAxis.renderer.labels.template.fill = am4core.color('white');
    //dateAxis.minZoomCount = 3;
    dateAxis.interactionsEnabled = false;
    
    dateAxis.strictMinMax = true;
    dateAxis.cloneTooltip = false;
    dateAxis.dateFormats.setKey("minute", {
      hour: 'numeric',
      minute: 'numeric',
    });
    dateAxis.periodChangeDateFormats.setKey("minute", {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
    });
    dateAxis.tooltipDateFormat = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };
    
    dateAxis.min = chartData[0].date.getTime();
    dateAxis.max = chartData[chartData.length - 1].date.getTime();
    // dateAxis.groupData = true;
    // dateAxis.groupInterval = { timeUnit: 'second', count: 500 };
    
    const dateRange = dateAxis.max - dateAxis.min;

    chartData.forEach((entry, i) => {
      const next = chartData[i + 1];
      if (!next) {
        return;
      }

      if (next.date.getTime() - entry.date.getTime() > 1000 * 60) {
        const axisBreak = dateAxis.axisBreaks.create();
        axisBreak.startDate = entry.date;
        axisBreak.endDate = next.date;
        axisBreak.opacity = 0.1;
        axisBreak.interactionsEnabled = false;
        //axisBreak.disabled = true;

        //const d = (axisBreak.endValue - axisBreak.startValue) / dateRange;
        axisBreak.breakSize = 0.0001;

      }
    });
    
    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.grid.template.stroke = am4core.color('white');
    valueAxis.renderer.labels.template.fill = am4core.color('white');
    valueAxis.marginTop = 20;
    const series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.dateX = "date";
    series.dataFields.valueY = "SPLon";
    series.propertyFields.stroke = "color";
    series.propertyFields.strokeWidth = "strokeWidth";
    series.simplifiedProcessing = true;
    series.skipRangeEvent = true;
    series.snapTooltip = true;
    series.tooltipPosition = 'fixed';
    series.showTooltipOn ="hit";
    series.cloneTooltip = false;
   // series.cursorTooltipEnabled = false;
    //series.ignoreMinMax =true;
    //series.connect = false;
    //series.cursorTooltipEnabled = false;

    /*chart.cursor = new am4charts.XYCursor();
    chart.cursor.lineX.stroke = am4core.color('white');
    chart.cursor.lineX.strokeWidth = 0.5;
    chart.cursor.lineX.strokeOpacity = 0.25;
    chart.cursor.lineY.stroke = am4core.color('white');
    chart.cursor.lineY.strokeWidth = 0.5;
    chart.cursor.lineY.strokeOpacity = 0.25;
    chart.cursor.cloneTooltip = false;*/
    
    //chart.cursor.snapToSeries = [series];
    //chart.cursor.trackable = false;
    //chart.cursor.maxTooltipDistance = 0;
    //chart.cursor.tooltipPosition = 'fixed';
    //chart.cursor.interactionsEnabled = false;
    
    const scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX = scrollbarX;
    
    chart.scrollbarX.showSystemTooltip = false;
    chart.scrollbarX.thumb.showSystemTooltip = false;
    chart.scrollbarX.startGrip.showSystemTooltip = false;
    chart.scrollbarX.endGrip.showSystemTooltip = false;
    chart.scrollbarX.minHeight = 4;
    chart.scrollbarX.dy = -16;
    
    chart.scrollbarX.startGrip.scale = 0.7;
    chart.scrollbarX.endGrip.scale = 0.7;
    //chart.scrollbarX.unselectedOverlay.fill = am4core.color("#ffffff");
    //chart.scrollbarX.unselectedOverlay.fillOpacity = 0.2;
    
    chart.scrollbarX.startGrip.background.fill = am4core.color("#000000");
    chart.scrollbarX.endGrip.background.fill = am4core.color("#000000");
    chart.scrollbarX.thumb.background.fill = am4core.color("#ffffff");//("#00b9ed");
    chart.scrollbarX.thumb.minHeight = 4;
    //chart.scrollbarX.startGrip.icon.stroke = am4core.color("#8A5658");
    //chart.scrollbarX.endGrip.icon.stroke = am4core.color("#8A5658");
    //chartRef.current = chart;
    
    // Applied on hover
    //chart.scrollbarX.startGrip.background.states.getKey("hover").properties.fill = am4core.color("#BC8C8A");
    //chart.scrollbarX.endGrip.background.states.getKey("hover").properties.fill = am4core.color("#BC8C8A");
    //chart.scrollbarX.thumb.background.states.getKey("hover").properties.fill = am4core.color("#BC8C8A");
    
    // Applied on mouse down
    //chart.scrollbarX.startGrip.background.states.getKey("down").properties.fill = am4core.color("#AD7371");
    //chart.scrollbarX.endGrip.background.states.getKey("down").properties.fill = am4core.color("#AD7371");
    //chart.scrollbarX.thumb.background.states.getKey("down").properties.fill = am4core.color("#AD7371");

    return () => chartRef.current?.dispose();
  }, [ containerRef.current, data ])

  return <div>
    <h3 style={{ paddingLeft: '1rem' }}>
      ANC chart <Span>{name}</Span>
      <Legend>
        <div className="device-off">Device OFF</div>
        <div className="off">ANC OFF</div>
        <div className="on">ANC ON</div>
      </Legend>
      <ReactTooltip className="tooltip" border borderColor="#fffffe" backgroundColor="#010d17"/>
    </h3>
    <div style={{ height: 500, marginTop: 20 }} ref={containerRef}></div>
  </div>;
}