import qs from 'query-string';
import { useQuery } from 'react-query';

const API_URL = process.env.API_URL;

async function _get<T>(endpoint: string, query?: Record<string, any>): Promise<T> {
  const res = await fetch(`${API_URL}${endpoint}?${qs.stringify(query ?? {})}`);
  if (res.status < 400) {
    return res.json() as Promise<T>;
  } else {
    const error = await res.json();
    throw error;
  }
}

interface Machine {
  customerId: string;
  manufacturer: string;
  model: string;
  serialNo: string;
}

async function getMachines(customer: string) {
  return _get<Machine[]>('/machines', { customer });
}

/**
 * Helper hook for getting the machines list
 * @param customer customer id
 */
export const useAPIMachines = (customer: string) => useQuery(
  [ 'machines', customer ],
  () => getMachines(customer),
);

export interface ChartEntry {
  SPLon: number;
  anc: number;
  date: number;
}

async function getChartData(customer: string, serialNo: string) {
  return _get<ChartEntry[]>('/chart', { customer, serialNo });
}

/**
 * Helper hook for getting chart data for a machine
 * @param customer customer id
 * @param serialNo machine serial number
 */
export const useAPIChartData =(customer: string, serialNo?: string) => useQuery(
  [ 'chart', customer, serialNo ],
  () => serialNo ? getChartData(customer, serialNo) : undefined,
);